 import {  createStore  } from 'vuex'
 import createPersistedState from 'vuex-persistedstate'

 const vuex = createStore({
     plugins: [createPersistedState()],
     state: {
         agence: '',
         token: null,
         basket: [],
         temporal: null,
         quote: null,
         wallet: null,
         virementType: null
     },

     mutations: {
         save_wallet (state, o) {
             state.wallet = o
         },

         save_virement (state, o) {
             state.virementType = o
         },

         save_temporal (state, o) {
             state.temporal = o
         },

         save_quote (state, o) {
             state.quote = o
         },

         save_agence (state, position) {
             state.agence = position
         },

         save_basket (state, v) {
             state.basket.push(v)
         },

         rewrite_basket (state, v) {
             state.basket = v
         },

         save_token (state, position) {
             state.token = position
         },

         logout (state) {
             state.token = null
             state.agence = ''
             state.basket = []
             state.temporal= null
             state.quote= null
         },
     },

     actions: {
         saveVirement ({ commit }, d) {
             commit('save_virement', d)
         },
         saveWallet ({ commit }, d) {
             commit('save_wallet', d)
         },
         saveTemporalItem ({ commit }, d) {
             commit('save_temporal', d)
         },
         saveQuote ({ commit }, d) {
             commit('save_quote', d)
         },
         addToBasket ({ commit }, d) {
             commit('save_basket', d)
         },
         saveBasket ({ commit }, d) {
             commit('rewrite_basket', d)
         },
         saveAgence ({ commit }, a) {
             commit('save_agence', a)
         },
         saveToken ({ commit }, c) {
             commit('save_token', c)
         },
         logout ({ commit }, e) {
             commit('logout', e)
         },
     },

     getters: {
         trackAgence: state => state.agence,
         token: state => state.token,
         trackBasket: state => state.basket,
         trackTemporal: state => state.temporal,
         trackQuote: state => state.quote,
         trackWallet: state => state.wallet,
         trackVirement: state => state.virementType
     }
 })

 export default vuex
