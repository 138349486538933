import { createWebHistory, createRouter } from 'vue-router';
import pathLoader from '../plugins/loader'
import store from '../store'

const routes = [{
        path: '/',
        name: 'homePrivate',
        component: pathLoader(null, 'homePrivate'),
        redirect: 'commandes',
        children: [{
                path: '/commandes',
                name: 'commandes',
                component: pathLoader('commandes'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/caisses',
                name: 'caisses',
                component: pathLoader('caisses'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/controleCaisse',
                name: 'controleCaisse',
                component: pathLoader('controleCaisse'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/transfere',
                name: 'transfere',
                component: pathLoader('transfere'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/transfereDeCaisse',
                name: 'transfereDeCaisse',
                component: pathLoader('transfereDeCaisse'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/historique',
                name: 'historique',
                component: pathLoader('historique'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/caisseDetail',
                name: 'caisseDetail',
                component: pathLoader('caisseDetail'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/controle',
                name: 'controle',
                component: pathLoader('controle'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailsCommande',
                name: 'detailsCommande',
                component: pathLoader('detailsCommande'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/banques',
                name: 'banques',
                component: pathLoader('banques'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/banqueDetail',
                name: 'banqueDetail',
                component: pathLoader('banqueDetail'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/validation',
                name: 'validation',
                component: pathLoader('validation'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/virement',
                name: 'virement',
                component: pathLoader('virement'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/remise',
                name: 'remise',
                component: pathLoader('remise'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/bankRemise',
                name: 'bankRemise',
                component: pathLoader('bankRemise'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/retrait',
                name: 'retrait',
                component: pathLoader('retrait'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/mobileTransfere',
                name: 'mobileTransfere',
                component: pathLoader('mobileTransfere'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/facture',
                name: 'facture',
                component: pathLoader('facture'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/nouvellefacture',
                name: 'nouvellefacture',
                component: pathLoader('nouvellefacture'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailFacture',
                name: 'detailFacture',
                component: pathLoader('detailFacture'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: pathLoader('login'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;